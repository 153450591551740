import React, { useRef, useContext, useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CustomerContext from "../context/CustomerProvider"
import queryString from "query-string"
import { Link } from "gatsby"

const ResetPassword = props => {
  const customerValue = useContext(CustomerContext)
  const customer = customerValue?.customer
  const getCustomerByEmail = customerValue?.getCustomerByEmail
  const sendResetPassLink = customerValue?.sendResetPassLink
  const verifyResetPassToken = customerValue?.verifyResetPassToken
  const resetPassword = customerValue?.resetPassword

  const { token } = queryString.parse(props.location.search)

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (customer) window.location.replace("/")
  }, [customer])

  useEffect(() => {
    if (token) {
      verifyResetPassToken(token)
        .then(response => {
          console.log({ response })
          if (response.success) {
            if (response?.decoded?.data) setData(response.decoded.data)
          }
        })
        .finally(() => setLoading(false))
    } else setLoading(false)
  }, [token])

  if (loading) return null

  return (
    <Layout>
      <SEO title={"Reset Password"} />
      <div className="page-headline">
        <div className="container">
          {token ? (
            <>
              {data ? (
                <ResetPass data={data} resetPassword={resetPassword} />
              ) : (
                <div className="w-100 m-auto" style={{ maxWidth: 600 }}>
                  <div className="text-center">
                    <div className="alert alert-danger" role="alert">
                      <span>
                        Sorry, your reset password request link is invalid click
                        button below to request new reset password link
                      </span>
                    </div>
                    <Link to="/reset-password" className="btn btn-outline-dark">
                      Request new reset password link
                    </Link>
                  </div>
                </div>
              )}
            </>
          ) : (
            <SendResetPassLink
              getCustomerByEmail={getCustomerByEmail}
              sendResetPassLink={sendResetPassLink}
            />
          )}
        </div>
      </div>
    </Layout>
  )
}

const ResetPass = ({ data, resetPassword }) => {
  const passwordRef = useRef()
  const confirmPasswordRef = useRef()
  const [loading, setLoading] = useState(false)
  const [res, setRes] = useState(null)

  async function handleSubmit(e) {
    e.preventDefault()

    const pass = passwordRef.current.value
    const confirmPass = confirmPasswordRef.current.value
    if (pass !== confirmPass) {
      return setRes({
        success: false,
        message: ["Mismatch password."],
      })
    }

    try {
      setLoading(true)
      setRes(null)

      const payload = [
        {
          id: data.id,
          authentication: { force_password_reset: false, new_password: pass },
        },
      ]

      const reset_pass = await resetPassword(payload)
      console.log({ reset_pass })
      if (reset_pass?.status) {
        setRes({
          success: false,
          message: Object.values(reset_pass.errors),
        })
      } else {
        setRes({
          success: true,
          message: ["New password was successfully saved."],
        })

        passwordRef.current.value = ""
        confirmPasswordRef.current.value = ""
      }
    } catch (error) {
      console.log(error)
      setRes({
        success: false,
        message: ["Oops! Reset password request failed."],
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="w-100 m-auto" style={{ maxWidth: 600 }}>
      <div className="text-center">
        <h3>Create new password</h3>
      </div>

      {res && (
        <div
          className={`alert ${res.success ? "alert-success" : "alert-danger"}`}
          role="alert"
        >
          <ul>
            {res.message.map((msg, i) => (
              <li key={i}>{msg}</li>
            ))}
          </ul>
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="password">Password</label>
          <input
            ref={passwordRef}
            type="password"
            className="form-control"
            id="password"
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            ref={confirmPasswordRef}
            type="password"
            className="form-control"
            id="confirmPassword"
            required
          />
        </div>

        <button
          type="submit"
          className="btn btn-dark btn-block text-uppercase"
          disabled={loading}
        >
          {loading ? "loading.." : "reset password"}
        </button>
      </form>
    </div>
  )
}

const SendResetPassLink = ({ getCustomerByEmail, sendResetPassLink }) => {
  const emailRef = useRef()
  const [isSend, setIsSend] = useState(false)
  const [res, setRes] = useState(null)

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setIsSend(true)
      setRes(null)

      const user = await getCustomerByEmail(emailRef.current.value)
      if (user?.data?.length) {
        const link = await sendResetPassLink(user.data[0])
        if (link.status === 200) {
          setRes({
            success: true,
            message:
              "Check your mail, we have sent a reset passwod link to your email.",
          })
        } else {
          setRes({ success: false, message: "Oops! Email address not found." })
        }
      } else {
        setRes({ success: false, message: "Oops! Email address not found." })
      }
    } catch (error) {
      console.log(error)
      setRes({
        success: false,
        message: "Oops!  Reset password request failed.",
      })
    } finally {
      setIsSend(false)
    }
  }

  return (
    <div className="w-100 m-auto" style={{ maxWidth: 600 }}>
      <div className="text-center">
        <h3>Reset password</h3>
        <p>
          Fill in your email below to request a new password. An email will be
          sent to the address below containing a link to verify your email
          address.
        </p>
      </div>

      {res && (
        <div
          className={`alert ${res.success ? "alert-success" : "alert-danger"}`}
          role="alert"
        >
          {res.message}
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="email">Email Address</label>
          <input
            ref={emailRef}
            type="email"
            className="form-control"
            id="email"
            required
          />
        </div>

        <button
          type="submit"
          className="btn btn-dark btn-block text-uppercase"
          disabled={isSend}
        >
          {isSend ? "loading.." : "reset password"}
        </button>
      </form>
    </div>
  )
}

export default ResetPassword
